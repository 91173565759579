<template>
  <div class="hold-hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Encabezados -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <!-- titulo -->
              <div class="col-sm-6">
                <h5 class="mb-0">Calificacion</h5>
              </div>
              <!-- /.titulo -->
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Calificaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- CUERPO SECTION GENERAL -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-3 pt-2 pb-2">
                  <h5 class="mb-0">Calificaciones</h5>
                </div>
              </div>
              <div class="card-body">
                <!-- 
            |------------------------------------------
            |CREACION O EDICION CALIFICACION
            |------------------------------------------
                -->
                <div class="row">
                  <!-- Campo Nombre form -->
                  <div class="col-md-3 col-sm-12">
                    <div class="form-group">
                      <label for="nombre">Nombre</label>
                      <input
                      :disabled="definitivo==1"
                        type="text"
                        class="form-control form-control-sm"
                        id="razon_social"
                        v-model="form.nombre"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!-- /.Campo Nombre form -->
                  <!-- Campo fecha inicial form -->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="estado">Fecha Inicial</label>
                      <input
                      :disabled="definitivo==1"
                        type="date"
                        class="form-control form-control-sm"
                        id="fecha_inicial"
                        v-model="form.fecha_inicial"
                        :class="
                          $v.form.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!-- /. Campo fecha inicial form -->
                  <!-- Campo fecha final form -->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="estado">Fecha Final</label>
                      <input
                      :disabled="definitivo==1"
                        type="date"
                        class="form-control form-control-sm"
                        id="fecha_final"
                        v-model="form.fecha_final"
                        :class="
                          $v.form.fecha_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <!-- /. Campo fecha final form -->
                  <!-- Campo linea de negocio-->
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="linea_negocio">Estado</label>
                      <select
                      :disabled="definitivo==1"
                        class="form-control form-control-sm"
                        v-model="form.estado"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.id"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- 
            |--------------------------------------------------------
            |AGREGAR ITEMS DE CALIFICACION CON SU RESPECTIVA PESO %
            |--------------------------------------------------------
                -->
                <div class="row" v-if="form.id">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="accordion" id="accordionExample">
                      <div class="card bg-light border-danger">
                        <div class="card-header" id="headingOne">
                          <h5 class="mb-0">
                            <button
                              class="btn btn-secondary"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapse1"
                              aria-expanded="false"
                              aria-controls="collapse1"
                              v-on:click="loadPorcentajes"
                            >
                              Registrar Porcentajes General Calificación
                              <span
                                v-if="form.estado_porcentaje_calificacion == 2"
                                class="badge badge-success"
                                >Definitivo</span
                              >
                              <span v-else class="badge badge-danger"
                                >Borrador</span
                              >
                            </button>
                          </h5>
                        </div>

                        <div
                          id="collapse1"
                          class="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <form
                              v-on:submit.prevent="save_procentajeCalificacion"
                            >
                              <div class="row">
                                <!-- Campo item de calificacion -->
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="item_evaluaciones"
                                      >Items de evaluacion</label
                                    >
                                    <select
                                      class="form-control form-control-sm"
                                      v-model="
                                        form_porcentajes.tipo_calificacion
                                      "
                                      :disabled="definitivo==1"
                                      :class="
                                        $v.form_porcentajes.tipo_calificacion
                                          .$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    >
                                      <option value="0">OTRO</option>
                                      <option
                                        v-for="items in listasForms.itemsEvaluacion"
                                        :key="items.id"
                                        :value="items.numeracion"
                                      >
                                        {{ items.descripcion }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <!-- /.Campo item de calificacion-->
                                <!-- campo oculto de nombre personalizado -->
                                <div
                                  class="col-md-3"
                                  v-if="form_porcentajes.tipo_calificacion == 0"
                                >
                                  <div class="form-group">
                                    <label for="item_evaluaciones"
                                      >Nombre</label
                                    >
                                    <input
                                    :disabled="definitivo==1"
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="nombre"
                                      v-model="form_porcentajes.nombre"
                                      :class="
                                        $v.form_porcentajes.nombre.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    />
                                  </div>
                                </div>
                                <!-- /. campo oculto de nombre personalizado -->
                                <!-- Campo Porcentaje -->
                                <div class="col-md-3">
                                  <label for="item_evaluaciones"
                                    >Porcentaje</label
                                  >
                                  <div class="input-group">
                                    <input
                                    :disabled="definitivo==1"
                                      type="number"
                                      class="form-control form-control-sm"
                                      id="porcentaje"
                                      v-model="form_porcentajes.porcentaje"
                                      :class="
                                        $v.form_porcentajes.porcentaje.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    />
                                    <div class="input-group-append">
                                      <span
                                        class="input-group-text form-control-sm"
                                        >%</span
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!-- /.Campo Porcentaje -->
                                <!-- Boton de registro -->
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <button
                                    :disabled="definitivo==1"
                                      style="margin-top: 25px"
                                      type="butestado_porcentaje_calificacionton"
                                      class="btn btn-primary"
                                    >
                                      <i class="fas fa-paper-plane"></i> Guardar
                                    </button>
                                  </div>
                                </div>
                                <!-- /. Boton de registro -->
                              </div>
                            </form>
                            <!-- TABLA PORCENTAJES -->
                            <div>
                              <table class="table">
                                <thead class="thead-dark">
                                  <tr>
                                    <th scope="col">Item a calificar</th>
                                    <th scope="col">Porcentaje</th>
                                    <th scope="col">Acciones</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in porcentajes_calificacion"
                                    :key="item.id"
                                  >
                                    <td v-if="item.tipo_calificacion == 0">
                                      {{ item.nombre }}
                                    </td>
                                    <td v-else>{{ item.nomItemEvaluado }}</td>
                                    <td>{{ item.porcentaje }}</td>
                                    <td>
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-danger"
                                        v-on:click="destroyPorcentaje(item.id)"
                                        v-if="
                                          $store.getters.can(
                                            'hidrocarburos.porcentajesCalificaciones.delete'
                                          ) &&
                                          form.estado_porcentaje_calificacion ==
                                            1
                                        "
                                      >
                                        <i class="fas fa-trash-alt"></i>
                                      </button>
                                      |
                                      <button
                                        type="button"
                                        class="btn btn-sm btn-success"
                                        v-if="
                                          item.tipo_calificacion == 1 &&
                                          form.estado_porcentaje_calificacion ==
                                            1 &&
                                          form.estado_calificacion_hse == 1
                                        "
                                        v-on:click="
                                          lanzarModal_calificacion_hse()
                                        "
                                      >
                                        <i class="fa fa-retweet"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!-- Boton para cambiar estado de la calificacion general -->
                            <div class="">
                              <button
                                type="button"
                                :disabled="definitivo==1"
                                class="btn btn-primary"
                                v-if="
                                  val_total_porcentaje == 100 &&
                                  form.estado_porcentaje_calificacion == 1
                                "
                                v-on:click="save_estado_porcentaje()"
                              >
                                <i class="fa fa-share-square"></i> Guardar
                                Porcentajes General
                              </button>
                            </div>
                            <!-- /. Boton para cambiar estado de la calificacion general -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Panel asocia detalle calificacion HSE -->
                <CalificacionesHSE
                  :bandera="bandera"
                  :calificacion="form.id"
                  v-if="bandera == 2 && form.estado_calificacion_hse == 1"
                  @bandera_hijo_emit="bandera = $event"
                  @cambio_estado_emit="form.estado_calificacion_hse = $event"
                ></CalificacionesHSE>
                <!-- 
            |------------------------------------------
            |ASOCIACION EMPRESAS
            |------------------------------------------
                -->
                <div
                  class="row"
                  v-if="form.estado_porcentaje_calificacion == 2"
                >
                  <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="card">
                      <div class="card-header text-center">
                        <button
                          class="btn btn-primary"
                          data-target="#Modal_lineas"
                          data-toggle="modal"
                          v-if="form.estado == 1"
                          :disabled="definitivo==1"
                        >
                          <i class="fa fa-plus"></i> Agregar Empresa
                        </button>
                      </div>
                      <div class="card-body">
                        <!-- 
                            |------------------------------------------
                            |LISTA DE LAS EMPRESAS ASOCIADAS
                            |------------------------------------------
                        -->
                        <div id="accordion" v-if="form.empresas">
                          <div
                            class="card"
                            v-for="empresa in form.empresas"
                            :key="empresa.id"
                          >
                            <div
                              class="card-header card bg-light"
                              id="headingOne"
                            >
                              <div class="row">
                                <div class="col-md-6">
                                  <h5 class="mb-0">
                                    <button
                                      class="btn btn-info"
                                      data-toggle="collapse"
                                      v-bind:data-target="
                                        '#collapempresa' + empresa.id
                                      "
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                      v-on:click="
                                        loaddatapercentage(empresa.id)
                                      "
                                    >
                                      {{ empresa.razon_social }} |
                                      <span class="badge badge-success">
                                        {{
                                          empresa.pivot.resultado_general
                                        }}</span
                                      >
                                      %
                                    </button>
                                  </h5>
                                </div>
                                <div class="col-md-6">
                                  <button
                                    class="btn btn-danger"
                                    style="float: right"
                                    v-if="form.estado == 1"
                                  >
                                    <i
                                      class="fa fa-trash"
                                      v-on:click="
                                        assingOrRevokeEmpresa(2, empresa.id)
                                      "
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div
                              v-bind:id="'collapempresa' + empresa.id"
                              class="collapse"
                              aria-labelledby="headingOne"
                              data-parent="#accordion"
                            >
                              <!-- 
                            |------------------------------------------
                            |DETALLE ITEMS
                            |------------------------------------------
                              -->
                              <div class="card-body">
                                <table class="table">
                                  <thead class="thead-dark">
                                    <tr>
                                      <th scope="col">Item</th>
                                      <th scope="col">Peso General</th>
                                      <th scope="col">Calificacion</th>
                                      <th scope="col">Acciones</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="item in detallearrayempresas"
                                      :key="item.id"
                                    >
                                      <th
                                        v-if="
                                          item.porcentaje_item_evaluacion
                                            .calificacion_id == form.id
                                        "
                                      >
                                        {{
                                          item.porcentaje_item_evaluacion.nombre
                                        }}
                                      </th>
                                      <td
                                        v-if="
                                          item.porcentaje_item_evaluacion
                                            .calificacion_id == form.id
                                        "
                                      >
                                        {{
                                          item.porcentaje_item_evaluacion
                                            .porcentaje
                                        }}
                                        %
                                      </td>
                                      <td
                                        v-if="
                                          item.porcentaje_item_evaluacion
                                            .calificacion_id == form.id
                                        "
                                      >
                                        {{ item.total_porcentaje_empresa }} %
                                      </td>
                                      <td
                                        v-if="
                                          item.porcentaje_item_evaluacion
                                            .calificacion_id == form.id &&
                                          item.detalle_calificacion[0] !==
                                            undefined
                                        "
                                      >
                                        <button
                                          class="btn btn-danger"
                                          v-if="form.estado == 1"
                                          v-on:click="
                                            eliminarCalificacion(
                                              item.porcentaje_item_evaluacion
                                                .tipo_calificacion,
                                              item.porcentaje_item_evaluacion
                                                .id,
                                              item.empresa_id,
                                              item.porcentaje_item_evaluacion
                                                .nombre,
                                              item.total_porcentaje_empresa
                                            )
                                          "
                                        >
                                          <i class="fa fa-trash"></i>
                                        </button>
                                        |
                                        <button
                                          class="btn btn-success"
                                          v-on:click="
                                            
                                            verDetalle(
                                              item.empresa_id,
                                              item.porcentajes_items_evaluaciones_id,
                                              item.porcentaje_item_evaluacion
                                                .tipo_calificacion,
                                              item.detalle_calificacion[0]
                                                .resultado,
                                              item.porcentaje_item_evaluacion
                                                .porcentaje,
                                              item.porcentaje_item_evaluacion
                                                .nombre,
                                              item.total_porcentaje_empresa,
                                              item.detalle_calificacion[0]
                                                .nominador,
                                              item.detalle_calificacion[0]
                                                .denominador,
                                              item.resultado_calificacion,
                                              item.detalle_calificacion
                                            )
                                          "
                                        >
                                          <i class="fa fa-eye"></i>
                                        </button>
                                      </td>
                                      <td
                                        v-if="
                                          item.porcentaje_item_evaluacion
                                            .calificacion_id == form.id &&
                                          item.detalle_calificacion[0] ==
                                            undefined
                                        "
                                      >
                                        <button
                                        :disabled="definitivo==1"
                                          class="btn btn-info"
                                          alt="Calificar"
                                          v-on:click="
                                            cargamodales(
                                              item.porcentaje_item_evaluacion
                                                .tipo_calificacion,
                                              item.porcentaje_item_evaluacion
                                                .id,
                                              item.empresa_id,
                                              item.porcentaje_item_evaluacion
                                                .nombre,
                                              item.porcentaje_item_evaluacion
                                                .porcentaje
                                            )
                                          "
                                        >
                                          <i class="fa fa-plus"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- footer of the target -->
                      <div class="card-footer text-muted text-center">
                        <strong
                          ><u>{{ numero_empresas }}</u> Empresas
                          asociadas</strong
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- foot of the card -->
              <div class="card-footer">
                <div class="row">
                  <button
                  :disabled="definitivo==1"
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save_calificacion()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- 
            |--------------------------------------------------------
            |********************** SECCION MODALES *****************
            |--------------------------------------------------------
                -->
          <!-- modal asociar empresas -->
          <div class="modal fade" id="Modal_lineas">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">Agregar Empresa</h4>
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    id="close-modal"
                  >
                    Cancelar
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Cuerpo del modal -->
                <div class="modal-body">
                  <div class="row">
                    <!-- campo Nombre -->
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="descripcion">Empresa</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form_empresa.nombre"
                          :class="
                            $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="empresa in arrayEmpresas"
                            :key="empresa.id"
                            :value="empresa.id"
                          >
                            {{ empresa.razon_social }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- /. campo Nombre -->
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="assingOrRevokeEmpresa(1)"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
          <!-- Modal calificaciones libres -->
          <div class="modal fade" id="Modal_calificacion_libres">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">
                    {{ form_calificacion_libre.nombre }}
                  </h4>
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Cuerpo del modal -->
                <div class="modal-body">
                  <div class="row">
                    <!-- campo calificacion -->
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="descripcion"
                          >Calificacion <small>Max 100%</small></label
                        >
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="form_calificacion_libre.porcentaje"
                          :class="
                            $v.form_calificacion_libre.porcentaje.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-show="!$v.form_calificacion_libre.$invalid"
                    v-on:click="agregarCalificacion()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
          <!-- Modal calificacion logistica-->
          <div class="modal fade" id="Modal_calificacion_logistica">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">
                    {{ form_calificacion_logistica_reactivo.nombre }}
                  </h4>
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Cuerpo del modal -->
                <div class="modal-body">
                  <div class="row">
                    <!-- campo calificacion -->
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="descripcion">Consolidado Solicitudes</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="
                            form_calificacion_logistica_reactivo.programado
                          "
                          readonly
                          :class="
                            $v.form_calificacion_logistica_reactivo.programado
                              .$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <!-- campo calificacion -->
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="descripcion">Viajes Cerrados</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          id="viajes_cerrados_log"
                          readonly
                          v-model="
                            form_calificacion_logistica_reactivo.ejecutado
                          "
                          :class="
                            $v.form_calificacion_logistica_reactivo.ejecutado
                              .$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <!-- campo calificacion -->
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="descripcion"
                          >Calificacion
                          <span>{{
                            form_calificacion_logistica_reactivo.porcentaje
                          }}</span></label
                        >
                        <input
                          type="number"
                          id="calificacion_logistica"
                          class="form-control form-control-sm"
                          v-model="
                            form_calificacion_logistica_reactivo.calificacion
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-show="!$v.form_calificacion_logistica_reactivo.$invalid"
                    v-on:click="agregarCalificacion()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
          <!-- Modal detalles libre and administrativo-->
          <div class="modal fade bd-example-modal-lg" id="Modal_detalle">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">
                    {{ ver_detalle_reactivo.nombre }}
                  </h4>
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Cuerpo del modal -->
                <div class="modal-body">
                  <div class="row">
                    <!-- campo calificacion -->
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label for="descripcion">Peso General Item</label>
                        <div class="input-group mb-2">
                          <input
                          :disabled="definitivo==1"
                            type="number"
                            class="form-control"
                            v-model="ver_detalle_reactivo.porcentaje_general"
                            readonly
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label for="descripcion">Calificacion Porcentaje</label>
                        <div class="input-group mb-2">
                          <input
                            type="number"
                            class="form-control"
                            v-model="ver_detalle_reactivo.resultado_procentaje"
                            :disabled="definitivo==1"
                            readonly
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label for="descripcion">Calificacion peso Item</label>
                        <div class="input-group mb-2">
                          <input
                            type="number"
                            class="form-control"
                            v-model="ver_detalle_reactivo.resultado"
                            :disabled="definitivo==1"
                            readonly
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-show="!$v.form_calificacion_libre.$invalid"
                    v-on:click="agregarCalificacion()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
          <!-- Modal detalles libre and administrativo-->
          <div
            class="modal fade bd-example-modal-lg"
            id="Modal_detalle_logistica"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">
                    {{ ver_detalle_reactivo.nombre }}
                  </h4>
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Cuerpo del modal -->
                <div class="modal-body">
                  <div class="row">
                    <!-- campo calificacion -->
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label for="descripcion">Peso General Item</label>
                        <div class="input-group mb-2">
                          <input
                            type="number"
                            class="form-control"
                            v-model="ver_detalle_reactivo.porcentaje_general"
                            readonly
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label for="descripcion">Viajes Programados</label>
                        <div class="input-group mb-2">
                          <input
                            type="number"
                            class="form-control"
                            v-model="ver_detalle_reactivo.viajes_programados"
                            readonly
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label for="descripcion">Viajes Cumplidos</label>
                        <div class="input-group mb-2">
                          <input
                            type="number"
                            class="form-control"
                            v-model="ver_detalle_reactivo.viajes_cumplidos"
                            readonly
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label for="descripcion">Calificacion</label>
                        <div class="input-group mb-2">
                          <input
                            type="number"
                            class="form-control"
                            v-model="ver_detalle_reactivo.resultado"
                            readonly
                          />
                          <div class="input-group-prepend">
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-show="!$v.form_calificacion_libre.$invalid"
                    v-on:click="agregarCalificacion()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
          <!-- Modal hse -->
          <div class="modal fade bd-example-modal-lg" id="Modal_hse">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">Calificacion HSE</h4>
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Cuerpo del modal -->
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                        <p v-if="errors.length" class="text-danger">
                            <b>Por favor, corrija el(los) siguiente(s): </b>
                              <ul>
                                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                              </ul>
                          </p>
                    </div>
                    <div
                      class="col-md-6"
                      v-for="padre_hse in det_calificacion_hse"
                      :key="padre_hse.id"
                    >
                      <div class="card">
                        <div
                          class="modal-header bg-frontera-top-left pt-2 pb-2"
                        >
                          <strong class="text-white"
                            >{{ padre_hse.nombre }}
                            <span class="badge badge-success">{{
                              padre_hse.peso_porcentaje
                            }}</span>
                          </strong>
                        </div>
                        <div class="card-body">
                          <div
                            class="form-group"
                            v-for="hijo_hse in padre_hse.det_califaciones_hse"
                            :key="hijo_hse.id"
                          >
                            <label for="descripcion">{{
                              hijo_hse.nombre 
                            }}</label> <span class="badge badge-success">{{hijo_hse.peso_porcentaje}} % </span>
                            <div class="input-group mb-2">
                              <input
                                type="number"
                                class="form-control"
                                v-model="hijo_hse.calificacion"
                              />
                              <div class="input-group-prepend">
                                <div class="input-group-text">%</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="agregarCalificacion()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
          <!-- Modal detalles HSE-->
          <div class="modal fade bd-example-modal-lg" id="Modal_detalle_hse">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">Detalle Calificacion HSE</h4>
                  <button
                    type="button"
                    class="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Cuerpo del modal -->
                <div class="modal-body">
                  <div class="row">
                      <div class="col-md-12">
                         <table class="table">
                           <thead class="thead-dark">
                             <tr>
                               <th>Item</th>
                               <th>Sub item</th>
                               <th>Calificacion</th>
                             </tr>
                           </thead>
                           <tbody v-for="calificacion in form_calificacion_hse_reactivo" :key="calificacion.id">
                             <tr>
                               <th>{{calificacion.calificacion_hse.nombre}}</th>
                               <th>{{calificacion.nombre}}</th>
                               <th>{{calificacion.resultado}}</th>
                             </tr>
                           </tbody>
                         </table>
                      </div>
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                  :disabled="definitivo==1"
                    type="button"
                    class="btn btn-primary"
                    v-on:click="agregarCalificacion()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
              <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

    <script>
/* axios */
import axios from "axios";
import CalificacionesHSE from "./CalificacionesHSE.vue";

const $ = window.$;
/* validaciones */
import { required, maxlength } from "vuelidate/lib/validators";
export default {
  name: "CalificacionDetalleForm",
  components: {
    CalificacionesHSE,
  },
  data() {
    return {
      accion: "",
      definitivo:0,
      form: {}, //Arreglo con los campos principales del formulario
      form_porcentajes: {},
      form_calificacion_def: {},
      porcentajes_calificacion: {}, // Arreglo que captura los datos de porcentajes
      val_total_porcentaje: 0,
      arrayEmpresas: [], //array del v-select de sitiostipo_calificacion, id_porcentaje_calificacion
      form_empresa: {}, //Formulario creacion empresa
      detallearrayempresas: {},
      numero_empresas: 0,
      detalle_empresas: {},
      form_calificacion_libre: {},
      form_calificacion_logistica: {},
      form_calificacion_logistica_reactivo: {},
      form_calificacion_hse: {},
      form_calificacion_hse_reactivo: {},
      tipo_calificacion_global: "", //varible que guarda el tipo de calificacion activa
      ver_detalle: {},
      ver_detalle_reactivo: {},
      det_calificacion_hse: {}, //variable que guarda los campos especificios de la calificiacion logistica
      bandera: 1,
      listasForms: {
        //Listado de selects en formularios
        estados: [],
        itemsEvaluacion: [],
      },
      errors: [],
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      estado: {
        required,
      },
    },
    form_porcentajes: {
      tipo_calificacion: {
        required,
      },
      nombre: {
        required,
      },
      porcentaje: {
        required,
      },
    },
    form_calificacion_libre: {
      porcentaje: {
        required,
      },
    },
    form_calificacion_logistica_reactivo: {
      ejecutado: {
        required,
      },
      programado: {
        required,
      },
    },
    form_calificacion_hse_reactivo: {
      novedades_mantenimiento: {
        required,
      },
      novedades_hse: {
        required,
      },
      accidentes: {
        required,
      },
      noAtendido: {
        required,
      },
      estadisticas: {
        required,
      },
      planHse: {
        required,
      },
      Acciones_covid: {
        required,
      },
    },
  },
  methods: {
    /*
     |--------------------------------------------------------------------------
     | Funciones iniciales
     |--------------------------------------------------------------------------
     | getEstados() => Traer estado inicial
     | back() => Al componente inicial
     | init() => Inicia las variables de entorn
     | save_calificacion() => Guarla la calificacion general
     | getItemsCalificacion() => Lista items de calificacion
     */
    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    back() {
      return this.$router.replace("/Hidrocarburos/Calificaciones");
    },
    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.numero_empresas = this.form.empresas.length;
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }

      if(this.form.estado==2)
      {
        this.definitivo=1;
      }
    },
    save_calificacion() {
      if (!this.$v.form.$invalid) {
        if (this.form.fecha_inicial > this.form.fecha_final) {
          this.$swal({
            icon: "error",
            title: "La fecha inicial no puede ser mayor a la fecha final",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          axios({
            method: this.metodo,
            url: "/api/hidrocarburos/calificaciones",
            data: this.form,
          })
            .then((response) => {
              //se valida si la calificacion no esta duplicada
              if (response.data.estado == 1) {
                this.$swal({
                  icon: "error",
                  title:
                    "ya existe una calificacion para la fechas definidas...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.form = response.data.calificacion;
              } else {
                this.$swal({
                  icon: "success",
                  title: "la calificacion se guardo exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.form = response.data.calificacion;
                if(this.form.estado==2)
                {
                  this.definitivo=1;
                }
              }
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },
    getItemsCalificacion() {
      axios.get("/api/lista/33").then((response) => {
        this.listasForms.itemsEvaluacion = response.data;
      });
    },
    /*
     |--------------------------------------------------------------------------
     | Funciones porcentajes generales
     |--------------------------------------------------------------------------
     | loadPorcentajes() => Carga porcentajes asignados a la calificacion
     | save_procentajeCalificacion() => Guardar item y porcentaje para la calificacion
     | save_estado_porcentaje() => Cambia el estado de el detallle de asiganacion de porcentajes
     | destroyPorcentaje() => Elimina porcentaje general
     */
    loadPorcentajes() {
      axios
        .get("/api/hidrocarburos/Porcentajescalificaciones", {
          params: {
            id_calificacion: this.form.id,
          },
        })
        .then((response) => {
          this.porcentajes_calificacion = response.data;
          this.val_total_porcentaje = 0;
          for (let c = 0; c < this.porcentajes_calificacion.length; c++) {
            this.val_total_porcentaje =
              this.val_total_porcentaje +
              Number(this.porcentajes_calificacion[c].porcentaje);
          }
        });
    },
    save_procentajeCalificacion() {
      //variable para capturar el valor de los porcentajes
      var total_porcentaje = 0;
      var itemDuplicado = false;
      var porcentajeSuperado = false;
      //se valida campos diligenciados
      //valida solo campos del control list
      if (this.form_porcentajes.tipo_calificacion == 0) {
        //valido todos los campos
        if (
          this.form_porcentajes.tipo_calificacion &&
          this.form_porcentajes.porcentaje &&
          this.form_porcentajes.nombre
        ) {
          this.form_porcentajes.nombre = this.form_porcentajes.nombre.toUpperCase();
          //valida duplicados
          for (let i = 0; i < this.porcentajes_calificacion.length; i++) {
            total_porcentaje =
              total_porcentaje +
              Number(this.porcentajes_calificacion[i].porcentaje);

            if (
              this.form_porcentajes.nombre ==
              this.porcentajes_calificacion[i].nombre
            ) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: "El item ya se encuentra agregado!",
              });
              itemDuplicado = true;
            }
          }

          //se suma el total de porcentajes agregados
          total_porcentaje =
            total_porcentaje + Number(this.form_porcentajes.porcentaje);
          if (total_porcentaje > 100) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "El total de porcentajes no puede superar el 100%",
            });
            porcentajeSuperado = true;
          }

          //se validad si las validades fueron superadas
          if (itemDuplicado == false && porcentajeSuperado == false) {
            //realiza envio
            this.form_porcentajes.calificacion_id = this.form.id;
            axios({
              method: "POST",
              url: "/api/hidrocarburos/Porcentajescalificaciones",
              data: this.form_porcentajes,
            })
              .then((response) => {
                this.$swal({
                  icon: "success",
                  title: "la seccion se guardo exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.loadPorcentajes();
                this.loadsectionEmpresa();
                //limpia form caliificacion libre
                this.form_calificacion_libre = {};
              })
              .catch((e) => {
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error, vuelva a intentarlo..." + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        } else {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Debe diligencia todos los datos!",
          });
        }
      } else if (
        this.form_porcentajes.tipo_calificacion &&
        this.form_porcentajes.porcentaje
      ) {
        //valida si el item ya esta agregado
        for (let a = 0; a < this.porcentajes_calificacion.length; a++) {
          total_porcentaje =
            total_porcentaje +
            Number(this.porcentajes_calificacion[a].porcentaje);
          if (
            this.form_porcentajes.tipo_calificacion ==
            this.porcentajes_calificacion[a].tipo_calificacion
          ) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "El item ya se encuentra agregado!",
            });
            itemDuplicado = true;
          }
        }
        //se suma el total de porcentajes agregados
        total_porcentaje =
          total_porcentaje + Number(this.form_porcentajes.porcentaje);
        if (total_porcentaje > 100) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "El total de porcentajes no puede superar el 100%",
          });
          porcentajeSuperado = true;
        }

        //se validad si las validades fueron superadas
        if (itemDuplicado == false && porcentajeSuperado == false) {
          //realiza envio
          this.form_porcentajes.calificacion_id = this.form.id;
          axios({
            method: "POST",
            url: "/api/hidrocarburos/Porcentajescalificaciones",
            data: this.form_porcentajes,
          })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "la seccion se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.loadPorcentajes();
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Debe diligencia todos los datos!",
        });
      }
    },
    save_estado_porcentaje() {
      if (this.form.estado_calificacion_hse == 1) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text:
            "Debe agregar y guardar el detalle de items de la calificacion HSE!",
        });
      } else {
        this.$swal({
          title: "Desea registra la informacion?",
          text:
            "Recuerde que una vez registrados los porcentajes no se podran revertir los cambios!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Registrar!",
          cancelButtonText: "No, Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: "PUT",
              url: "/api/hidrocarburos/Porcentajescalificaciones",
              data: this.form,
            })
              .then((response) => {
                this.$swal({
                  icon: "success",
                  title: "los porcentajes se guardaron exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.$swal(
                  "Agregado!",
                  "Los porcentajes han sido grabados.",
                  "success"
                );
                this.form = response.data[0];
              })
              .catch((e) => {
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error, vuelva a intentarlo..." + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      }
    },
    destroyPorcentaje(idPorcentaje) {
      if (this.form.estado_calificacion_hse == 2) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "No se puede eliminar la calificacion!",
          footer:
            "<a href>Esta calificacion ya tiene asociado un proceso de asignacion HSE, debe comunicarse con el administrador del sistema para poder eliminar el item</a>",
        });
      } else {
        this.$swal({
          title: "Esta seguro de eliminar este item de porcentaje?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          // <--
          if (result.value) {
            axios
              .delete(
                "/api/hidrocarburos/Porcentajescalificaciones/" + idPorcentaje
              )
              .then(() => {
                this.loadPorcentajes();
                this.$swal({
                  icon: "success",
                  title: "Se elimino la seccion de manera exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
              });
          }
        });
      }
    },
    /*
     |--------------------------------------------------------------------------
     | Funciones Empresas
     |--------------------------------------------------------------------------
     | getDatosEmpresas() => Vselect empresas
     | llenarSelectEmpresas() => Carga datos del select de empresa
     | assingOrRevokeEmpresa() => asigna o elimina empresa de una calificacion
     */
    llenarSelectEmpresas() {
      axios
        .get("api/admin/empresas/lista")
        .then((response) => {
          // Obtenemos los datos
          let me = this;
          let respuesta = response.data;
          me.arrayEmpresas = respuesta;
          me.form_empresa.nombre = me.arrayEmpresas.find(
            (items) => items.id === me.idEmpresa
          );
        })
        .catch((e) => {
          // Capturamos los errores
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    assingOrRevokeEmpresa(accion, empresaId) {
      if (accion == 2) {
        this.form_empresa.nombre = empresaId;
        this.$swal({
          title: "Esta seguro de eliminar esta empresa?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          // <--
          if (result.value) {
            if (this.form_empresa.nombre) {
              //se agrega al arreglo el id de la calificacion
              this.form_empresa.id_calificacion = this.form.id;
              //se agrega al arreglo la accion a realizar
              this.form_empresa.accion = accion;
              axios({
                method: "PUT",
                url: "/api/hidrocarburos/calificaciones/empresa",
                data: this.form_empresa,
              })
                .then((response) => {
                  if (response.data == 2) {
                    this.$swal({
                      icon: "error",
                      title: "la empresa ya se encuentra asignada",
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                  } else if (response.data == 1) {
                    this.$swal({
                      icon: "success",
                      title: "la empresa se guardo exitosamente...",
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                    this.loadsectionEmpresa();

                    $("#Modal_lineas").modal("hide");
                  } else {
                    this.$swal({
                      icon: "success",
                      title: "la empresa se elimino...",
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                    this.loadsectionEmpresa();
                  }
                })
                .catch((e) => {
                  this.$swal({
                    icon: "error",
                    title:
                      "Ocurrio un error, Comuniquese con el administrador del sistema..." +
                      e,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                });
            } else {
              this.$swal({
                icon: "error",
                title: "Debe seleccionar una empresa",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          }
        });
      } else {
        if (this.form_empresa.nombre) {
          //se agrega al arreglo el id de la calificacion
          this.form_empresa.id_calificacion = this.form.id;
          //se agrega al arreglo la accion a realizar
          this.form_empresa.accion = accion;
          axios({
            method: "PUT",
            url: "/api/hidrocarburos/calificaciones/empresa",
            data: this.form_empresa,
          })
            .then((response) => {
              if (response.data == 2) {
                this.$swal({
                  icon: "error",
                  title: "la empresa ya se encuentra asignada",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else if (response.data == 1) {
                this.$swal({
                  icon: "success",
                  title: "la empresa se guardo exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.loadsectionEmpresa();
              } else {
                this.$swal({
                  icon: "success",
                  title: "la empresa se elimino...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.loadsectionEmpresa();
              }
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title:
                  "Ocurrio un error, Comuniquese con el administrador del sistema..." +
                  e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } else {
          this.$swal({
            icon: "error",
            title: "Debe seleccionar una empresa",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
    loadsectionEmpresa() {
      axios
        .get("api/hidrocarburos/calificaciones/empresa", {
          params: {
            nominacion_id: this.form.id,
          },
        })
        .then(
          (response) => {
            this.form = response.data[0];
            this.numero_empresas = this.form.empresas.length;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    loaddatapercentage(empresa_id) {
      axios
        .get("api/hidrocarburos/calificaciones/detalle_porcentaje", {
          params: {
            calificacion_id: this.form.id,
            empresa_id: empresa_id,
          },
        })
        .then(
          (response) => {
            this.detallearrayempresas = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    /*
     |--------------------------------------------------------------------------
     | Funciones detalle de las calificaciones
     |--------------------------------------------------------------------------
     | getDatosEmpresas() => Vselect empresas
     | llenarSelectEmpresas() => Carga datos del select de empresa
     | assingOrRevokeEmpresa() => asigna o elimina empresa de una calificacion
     */
    cargamodales(tipo_calificacion, item_id, empresa_id, nombre, porcentaje) {      
      //limpio variable global
      this.tipo_calificacion_global = "";

      if (tipo_calificacion == 0 || tipo_calificacion == 3) {
        //asigno variables global
        this.tipo_calificacion_global = tipo_calificacion;
        //open modal
        $("#Modal_calificacion_libres").modal("show");
        this.form_calificacion_libre.nombre = nombre;
        //porcentajes_items_evaluaciones_id
        this.form_calificacion_libre.porcentanje_id = item_id;
        //empresa_id
        this.form_calificacion_libre.empresa_id = empresa_id;
        //calificacion
        this.form_calificacion_libre.calificacion_id = this.form.id;
        //tipo de calificacion
        this.form_calificacion_libre.tipo_calificacion = this.tipo_calificacion_global;
      }
      if (tipo_calificacion == 2) {
        /* Logistica */
        this.tipo_calificacion_global = tipo_calificacion;

        //consulta de datos para calificacion logistica
        axios
          .get(
            "api/hidrocarburos/calificaciones/getdatosCalificacionLogistica",
            {
              params: {
                calificacion_id: this.form.id,
                empresa_id: empresa_id,
                tipo_calificacion_id: tipo_calificacion,
              },
            }
          )
          .then(
            (response) => {
              this.form_calificacion_logistica = {};

              this.form_calificacion_logistica.ejecutado =
                response.data.ejecutado;
              //pilas cambiar
              this.form_calificacion_logistica.programado = 15; //response.data.solicitado;

              this.form_calificacion_logistica.tipo_calificacion = tipo_calificacion;
              this.form_calificacion_logistica.item_id = item_id;
              this.form_calificacion_logistica.empresa_id = empresa_id;
              this.form_calificacion_logistica.nombre = nombre;
              this.form_calificacion_logistica.porcentaje = porcentaje;

              //
              if (
                this.form_calificacion_logistica.ejecutado == 0 ||
                this.form_calificacion_logistica.programado == 0
              ) {
                this.form_calificacion_logistica.calificacion = 0;
              } else {
                this.form_calificacion_logistica.peso_porcentual =
                  (100 * this.form_calificacion_logistica.ejecutado) /
                  this.form_calificacion_logistica.programado;

                this.form_calificacion_logistica.calificacion =
                  (parseInt(this.form_calificacion_logistica.porcentaje) +
                    this.form_calificacion_logistica.peso_porcentual) /
                  100;

                this.form_calificacion_logistica.calificacion = this.form_calificacion_logistica.calificacion.toFixed(
                  2
                );

                this.form_calificacion_logistica.peso_porcentual = this.form_calificacion_logistica.peso_porcentual.toFixed(
                  2
                );

                this.form_calificacion_logistica_reactivo = this.form_calificacion_logistica;
              }

              this.form_calificacion_logistica_reactivo = this.form_calificacion_logistica;
            },
            (error) => {
              console.log(error);
            }
          );
        $("#Modal_calificacion_logistica").modal("show");
        $("#calificacion_logistica").val(
          this.form_calificacion_logistica_reactivo.calificacion
        );
      } else if (tipo_calificacion == 1) {
        //variable que guarda el tipo de solicitud
        this.tipo_calificacion_global = tipo_calificacion;

        axios
          .get(
            "/api/hidrocarburos/Porcentajescalificaciones/list_Detalle_calificacion_hse",
            {
              params: {
                calificacion_id: this.form.id,
              },
            }
          )
          .then((response) => {
            this.form_calificacion_hse = response.data;
          });

        //abre Modal
        $("#Modal_hse").modal("show");

        //consulta de datos para calificacion HSE
        axios
          .get(
            "api/hidrocarburos/calificaciones/getdatosCalificacionLogistica",
            {
              params: {
                calificacion_id: this.form.id,
                empresa_id: empresa_id,
                tipo_calificacion_id: tipo_calificacion,
              },
            }
          )
          .then(
            (response) => {
              this.form_calificacion_hse = response.data.detalle_calificacion;
              this.det_calificacion_hse =
                response.data.calificacion_hse_general;

              for (
                let index = 0;
                index < this.det_calificacion_hse.length;
                index++
              ) {
                for (
                  let ondex = 0;
                  ondex <
                  this.det_calificacion_hse[index].det_califaciones_hse.length;
                  ondex++
                ) {
                  for (let a = 0; a < this.form_calificacion_hse.length; a++) {
                    if (
                      this.form_calificacion_hse[a].nombre ==
                      this.det_calificacion_hse[index].det_califaciones_hse[
                        ondex
                      ].nombre
                    ) {
                      this.det_calificacion_hse[index].det_califaciones_hse[
                        ondex
                      ].calificacion = this.form_calificacion_hse[a].resultado;
                    }
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    agregarCalificacion() {
      //se calida el tipo de calificacion
      if (
        this.tipo_calificacion_global == 0 ||
        this.tipo_calificacion_global == 3
      ) {
        if (this.form_calificacion_libre.porcentaje > 100) {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "La calificacion no puede ser mayor a 100%!",
          });
        } else {
          axios({
            method: "POST",
            url: "/api/hidrocarburos/calificaciones/detalle_porcentaje",
            data: this.form_calificacion_libre,
          })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Se agrego la calificacion...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.loadPorcentajes();
              this.loaddatapercentage(this.form_calificacion_libre.empresa_id);
              this.loadsectionEmpresa();
              this.form_calificacion_libre.porcentaje = 0;
              $("#Modal_calificacion_libres").modal("hide");
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title:
                  "Ocurrio un error,por favor comuniquese con el administrador del  sistema",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } //calificacion logistica
      } else if (this.tipo_calificacion_global == 2) {
        if (
          $("#calificacion_logistica").val() >
          this.form_calificacion_logistica_reactivo.porcentaje
        ) {
          var peso_general = this.form_calificacion_logistica_reactivo
            .porcentaje;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "La calificacion no puede ser mayor a " + peso_general,
          });
        } else {
          this.form_calificacion_def = this.form_calificacion_logistica_reactivo;
          this.form_calificacion_def.calificacion_id = this.form.id;

          axios({
            method: "POST",
            url: "/api/hidrocarburos/calificaciones/detalle_porcentaje",
            data: this.form_calificacion_def,
          })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Se agrego la calificacion...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.loadPorcentajes();
              this.loaddatapercentage(this.form_calificacion_def.empresa_id);
              this.loadsectionEmpresa();
              $("#Modal_calificacion_logistica").modal("hide");
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title:
                  "Ocurrio un error,por favor comuniquese con el administrador del  sistema",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      } else if (this.tipo_calificacion_global == 1) {
        this.errors = [];

        for (let a = 0; a < this.det_calificacion_hse.length; a++) {
          for (
            let b = 0;
            b < this.det_calificacion_hse[a].det_califaciones_hse.length;
            b++
          ) {
            if (
              this.det_calificacion_hse[a].det_califaciones_hse[b]
                .calificacion >
                this.det_calificacion_hse[a].det_califaciones_hse[b]
                  .peso_porcentaje ||
              this.det_calificacion_hse[a].det_califaciones_hse[b]
                .calificacion == ""
            ) {
              this.errors.push(
                "el campo " +
                  this.det_calificacion_hse[a].det_califaciones_hse[b].nombre +
                  " no puede ser mayor a " +
                  this.det_calificacion_hse[a].det_califaciones_hse[b]
                    .peso_porcentaje +
                  " o vacio"
              );
            }
          }
        }

        for (let index = 0; index < this.det_calificacion_hse.length; index++) {
          for (
            let ondex = 0;
            ondex <
            this.det_calificacion_hse[index].det_califaciones_hse.length;
            ondex++
          ) {
            for (let a = 0; a < this.form_calificacion_hse.length; a++) {
              if (
                this.form_calificacion_hse[a].nombre ==
                this.det_calificacion_hse[index].det_califaciones_hse[ondex]
                  .nombre
              ) {
                this.form_calificacion_hse[
                  a
                ].resultado = this.det_calificacion_hse[
                  index
                ].det_califaciones_hse[ondex].calificacion;
              }
            }
          }
        }

        var datos = {};
        datos.objeto = this.form_calificacion_hse;

        if (this.errors == "") {
          axios({
            method: "POST",
            url: "/api/hidrocarburos/calificaciones/save_datos_hse",
            data: datos,
          })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Se agrego la calificacion...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.loadPorcentajes();
              this.loaddatapercentage(this.form_calificacion_def.empresa_id);
              this.loadsectionEmpresa();
              $("#Modal_hse").modal("hide");
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title:
                  "Ocurrio un error,por favor comuniquese con el administrador del  sistema",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },
    eliminarCalificacion(
      tipo_calificacion,
      item_id,
      empresa_id,
      nombre,
      valor_porcentaje
    ) {
      var datos = {};
      datos.tipo_calificacion = tipo_calificacion;
      datos.item_id = item_id;
      datos.empresa_id = empresa_id;
      datos.nombre = nombre;
      datos.calificacion_id = this.form.id;
      datos.valor_porcentaje = valor_porcentaje;
      this.$swal({
        title: "Esta seguro de eliminar este item de porcentaje?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .get(
              "api/hidrocarburos/calificaciones/eliminar_detalle_porcentaje",
              {
                params: {
                  tipo_calificacion: tipo_calificacion,
                  item_id: item_id,
                  empresa_id: empresa_id,
                  nombre: nombre,
                  calificacion_id: this.form.id,
                  valor_porcentaje: valor_porcentaje,
                },
              }
            )
            .then(
              (response) => {
                this.$swal({
                  icon: "success",
                  title: "Se Elimino la calificacion con exito",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.loadPorcentajes();
                this.loaddatapercentage(empresa_id);
                this.loadsectionEmpresa();
              },
              (error) => {
                console.log(error);
              }
            );
        }
      });
    },
    verDetalle(
      empresa_id,
      porcentaje_items_evaluaciones_id,
      tipo_calificacion,
      resultado,
      porcentaje_general,
      nombre,
      resultado_procentaje,
      nominador,
      denominador,
      resultado_general,
      detalle_general
    ) {
      if (tipo_calificacion == 0 || tipo_calificacion == 3) {
        $("#Modal_detalle").modal("show");

        this.ver_detalle = {};
        this.ver_detalle.tipo_calificacion = tipo_calificacion;
        this.ver_detalle.resultado = resultado;
        this.ver_detalle.porcentaje_general = porcentaje_general;
        this.ver_detalle.nombre = nombre;
        this.ver_detalle.resultado_procentaje = resultado_procentaje;

        this.ver_detalle_reactivo = this.ver_detalle;
      } else if (tipo_calificacion == 2) {
        $("#Modal_detalle_logistica").modal("show");
        this.ver_detalle = {};
        this.ver_detalle.tipo_calificacion = tipo_calificacion;
        this.ver_detalle.resultado = resultado;
        this.ver_detalle.porcentaje_general = porcentaje_general;
        this.ver_detalle.nombre = nombre;
        this.ver_detalle.resultado_procentaje = resultado_procentaje;
        this.ver_detalle.viajes_programados = nominador;
        this.ver_detalle.viajes_cumplidos = denominador;
        this.ver_detalle_reactivo = this.ver_detalle;
      } else if (tipo_calificacion == 1) {
        axios
          .get(
            "/api/hidrocarburos/calificaciones/show_detalle_calificacion_hse",
            {
              params: {
                empresa_id: empresa_id,
                porcentaje_items_evaluaciones_id: porcentaje_items_evaluaciones_id,
              },
            }
          )
          .then((response) => {
            this.form_calificacion_hse_reactivo = response.data;
          });
        $("#Modal_detalle_hse").modal("show");
      }
    },
    calcularCalificacion() {
      this.form_calificacion_logistica.peso_porcentual =
        (100 * this.form_calificacion_logistica.ejecutados) /
        this.form_calificacion_logistica.programado;

      this.form_calificacion_logistica.calificacion =
        (this.form_calificacion_logistica.porcentaje *
          this.form_calificacion_logistica.peso_porcentual) /
        100;

      this.form_calificacion_logistica.calificacion = this.form_calificacion_logistica.calificacion.toFixed(
        2
      );
    },
    lanzarModal_calificacion_hse() {
      this.bandera = 2;
    },
  },
  mounted() {
    this.init();
    this.getEstados();
    this.getItemsCalificacion();
    this.llenarSelectEmpresas();
  },
};
</script>
